export const environment = {
  name: 'production',
  production: false,
  backendUrl: 'https://api.tamis.app.br/api/',
  chartBackendUrl:
    'https://chart-backend-prod-zoz3wkw56a-rj.a.run.app/graphql/',
  igestoAuthToken: 'igestoAuthToken',
  firebaseConfig: {
    apiKey: 'AIzaSyAt7DBe8Qlq0ISgeZNq4rF3SMoNzTv6wOc',
    authDomain: 'clarity-igesto.firebaseapp.com',
    projectId: 'clarity-igesto',
    storageBucket: 'clarity-igesto.appspot.com',
    messagingSenderId: '996053244722',
    appId: '1:996053244722:web:37f2c55abbc4f0e0e4952b',
  },
  loginTimeout: 3600, // this is equivalent to 1 hour -- time in seconds.
  cookiesExpiration: 60, // this is equivalent to 1 hour.
  templatesUrl: 'https://storage.googleapis.com/igesto-public-files/templates',
  importCreatePath: 'import-create',
  tenantId: 'production-48gfo',
  version: "1.1.2.475603f0",
};
